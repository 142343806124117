.carousel-bg{
    background-color: #f5f5dccf;
   /* background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px), linear-gradient(#fffbf1 0.1em, transparent 0.1em);*/
    background-size: 100% 1.2em;
}
.myCustomDivider::before {
    border-top-color: #282c34 !important;
}
@media (max-width: 2600px) {
    .text-arriving-list {
        font-size: 3.0rem;
        line-height: 3.0rem;
    }
}
@media (max-width: 960px) {
    .text-arriving-list {
        font-size: 2.9rem;
        line-height: 3rem;
    }
    .p-divider.p-divider-horizontal{
        margin: 2px 0;
        padding: 0 3px;
    }
}