.carousel-bg-match{
    background-color: #f5dcf2cf;
    background-size: 100% 1.2em;
}
.w-20{
    width: 3rem;
}
.h-20{
    height: 3rem;
}
@media (max-width: 2600px) {
    .text-arriving-list-match {
        font-size: 3.0rem;
        line-height: 3.0rem;
    }
}
@media (max-width: 960px) {
    .text-arriving-list-match {
        font-size: 2.9rem;
        font-weight: bold;
        line-height: 3rem;
    }
    .p-divider.p-divider-horizontal{
        margin: 2px 0;
        padding: 0 3px;
    }
}
